@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Source Sans 3", sans-serif;
}
body {
  background-color: #f7f7f8;
}
.menuitem.Mui-selected {
  color: red;
  border: 1px solid red;
}
h2 {
  @apply text-[#0F172A];
  font-weight: 400;
  font-size: 28px;
}
p {
  @apply text-[#727272];
}
h3 {
  @apply text-[#FECE51];
  font-weight: 500;
  font-size: 16px;
}
.image-container {
  display: inline-block;
  position: relative;
  width: 300px; /* Set a fixed width */
  height: 200px; /* Set a fixed height */
}

.image-wrapper {
  height: 100%; /* Ensure the wrapper takes the full height */
  overflow: hidden;
  position: relative;
  width: 100%; /* Ensure the wrapper takes the full width */
}

.image {
  border-radius: 10px;
  display: block;
  height: 100%; /* Ensure the image takes the full height of its container */
  object-fit: cover; /* Maintain aspect ratio and cover the container */
  transition: transform 0.3s ease-in-out;
  width: 100%; /* Ensure the image takes the full width of its container */
  z-index: 2; /* Ensure image is above the smoke */
}

.overlay {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  font-family: "Source Sans 3", sans-serif;
  position: absolute;
  top: 0;
  transition: opacity 0.3s ease-in-out;
  width: 100%;
}
.transparent-style {
  background-color: #ffffff;
  opacity: 0.6;
}
.text {
  color: #fff;
  font-size: 18px;
  text-align: center;
}

.image-container:hover .image {
  transform: scale(1.1);
}

.image-container:hover .overlay {
  opacity: 1;
}

.col-4 {
  margin-top: 40px;
}
.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  background-color: rgb(15 23 42 / var(--tw-bg-opacity));
  color: #f5eda1;
}
/* animation count  */

/* media queries
/* Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  h2 {
    font-size: 22px;
  }
  .text-smc {
    font-size: 16px;
    margin-bottom: 5px;
  }
  .padingm {
    padding: 0 10px 0 10px;
  }
}

/* Medium devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  h2 {
    font-size: 24px;
  }
  .padingm {
    padding: 0 10px 0 10px;
  }
  .searchBar form input {
    width: 100% !important;
  }
}

/* Large devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 1024px) {
  h2 {
    font-size: 26px;
  }
  .padingm {
    padding: 0 10px 0 10px;
  }
  ul.flex.items-center.gap-4.customeMenu {
    gap: 5px;
}

}

@layer utilities {
  .line-through-red::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
    background-color: red;
    transform: translateY(-50%);
  }
}
