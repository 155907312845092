/* LoadingOverlay.css */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  backdrop-filter: blur(5px); /* Blur the background */
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
