@import "../../responsive.scss";

.searchBar {
  .type {
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      padding: 16px 36px;
      border: 1px solid #999;
      border-bottom: none;
      cursor: pointer;
      background-color: white;
      text-transform: capitalize;

      &.active {
        background-color: #0f172a;
        color: white;
      }

      &:first-child {
        border-top-left-radius: 5px;
        border-right: none;
      }
      &:last-child {
        border-top-right-radius: 5px;
        border-left: none;
      }
    }
    .button2 {
      box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1),
        0 2px 4px -2px rgb(0 0 0 / 0.1);
      border: none;
      border-radius: 5px;
      cursor: pointer;
      background-color: #fece51;
      padding: 7px;

      @include sm {
        padding: 10px;
      }
    }
  }

  form {
    border: 1px solid #999;
    display: flex;
    justify-content: space-between;
    height: 64px;
    gap: 5px;

    @include sm {
      flex-direction: column;
      border: none;
    }

    input {
      border: none;
      padding: 0px 10px;
      width: 200px;

      @include lg {
        padding: 0px 5px;

        &:nth-child(2),
        &:nth-child(3) {
          width: 140px;
        }
      }
      @include md {
        width: 200px;
        &:nth-child(2),
        &:nth-child(3) {
          width: 200px;
        }
      }

      @include sm {
        width: 100% !important;
        &:nth-child(2),
        &:nth-child(3) {
          width: 100% !important;
        }
        padding: 20px;
        border: 1px solid #999;
      }
    }
    select {
      border: none;
      padding: 0px 10px;
      width: 200px;

      @include lg {
        padding: 0px 5px;

        &:nth-child(2),
        &:nth-child(3) {
          width: 140px;
        }
      }
      @include md {
        width: 200px;
        &:nth-child(2),
        &:nth-child(3) {
          width: 200px;
        }
      }

      @include sm {
        width: 100%;
        &:nth-child(2),
        &:nth-child(3) {
          width: 100%;
        }
        padding: 20px;
        border: 1px solid #999;
      }
    }
  }
}


