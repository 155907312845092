.cardProperty {
  display: flex;
  flex-direction: row;
  gap: 20px;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  padding: 15px;
  margin-bottom: 15px;
  border: 1px solid #e0e0e0;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
   
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
  }

  .cardImageContainer {
    width: 250px;
    height: 170px;
    border-radius: 12px;
    overflow: hidden;
    flex-shrink: 0;
    position: relative;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 12px;
      transition: transform 0.3s ease;
    }

    &:hover img {
      transform: scale(1.05);
    }
  }

  .cardTextContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;

    .cardProjectName {
      font-size: 1.2rem;
      font-weight: 700;
      color: #5c6b72;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      line-height: 1.2;
      background: linear-gradient(90deg, #6c757d, #5a636e);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      transition: transform 0.3s ease;

      &:hover {
        transform: translateX(20px) translateY(-2px) scale(1.05);
        color: #5c6b72;
      }
    }

    .cardTitle {
      font-size: 20px;
      font-weight: 700;
      color: #273d2f;
      line-height: 1.3;
      letter-spacing: -0.02em;
      background: linear-gradient(90deg, #000, #3b806f);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.12);
      transition: color 0.3s ease, transform 0.3s ease, text-shadow 0.3s ease;

      &:hover {
        color: #28a745;
        text-shadow: 3px 3px 8px rgba(0, 0, 0, 0.15);
        transform: translateX(20px) translateY(-3px) scale(1.05);
      }
    }

    .cardAddress {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 14px;
      color: #888;
      margin-top: 4px;

      img {
        width: 16px;
        height: 16px;
      }
    }

    .card-pricing {
      display: flex;
      gap: 16px;
      align-items: center;
      font-size: 18px;
      font-weight: 600;
      color: #e74c3c;

      .current-price {
        color: #e74c3c;
      }

      .suffix-price {
        font-size: 14px;
        color: #999;
        text-decoration: line-through;
      }
    }

    .bottom {
      display: flex;
      justify-content: space-between;
      gap: 24px;
      flex-wrap: wrap;

      .features {
        display: flex;
        gap: 16px;
        flex-wrap: wrap;
        font-size: 14px;
        color: #555;
        flex: 1;
        justify-content: flex-start;

        .feature {
          display: flex;
          align-items: center;
          gap: 8px;
          background-color: #f5f5f5;
          padding: 8px 16px;
          border-radius: 25px;
          font-size: 16px; /* Increase font size */
          font-weight: 500; /* Make font weight bolder */
          color: #34495e; /* Darker, more visible color */
          transition: background-color 0.3s ease, transform 0.3s ease;

          &:hover {
            background-color: #ecf0f1;
            transform: scale(1.05); /* Slight zoom effect on hover */
          }

          svg {
            color: #1abc9c; /* Icon color */
            font-size: 18px;
          }

          span {
            font-size: 16px; /* Increase font size for better readability */
            color: #34495e;
          }
        }
      }

      .icons {
        display: flex;
        gap: 24px;
        justify-content: space-between;
        flex-shrink: 0;
        margin-top: auto;

        .icon {
          padding: 12px;
          border: 2px solid #ddd;
          border-radius: 50%;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: background-color 0.3s ease, border 0.3s ease;

          &:hover {
            background-color: #ecf0f1;
            border-color: #1abc9c;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 16px;
    align-items: stretch;

    .cardImageContainer {
      width: 100%;
      height: 220px;
      margin-bottom: 16px;
    }

    .cardTextContainer {
      gap: 12px;
    }

    .cardProjectName {
      font-size: 1.5rem;
    }

    .cardTitle {
      font-size: 1.2rem;
    }

    .cardAddress {
      font-size: 12px;
    }

    .card-pricing {
      font-size: 16px;
      gap: 8px;
    }

    .features {
      flex-direction: column;
      gap: 8px;
    }

    .icons {
      gap: 16px;
    }
  }

  @media (max-width: 480px) {
    padding: 12px;

    .cardImageContainer {
      width: 100%;
      height: 200px;
      margin-bottom: 12px;
    }

    .cardTextContainer {
      gap: 10px;
    }

    .cardProjectName {
      font-size: 1.4rem;
    }

    .cardTitle {
      font-size: 1.1rem;
    }

    .cardAddress {
      font-size: 12px;
    }

    .card-pricing {
      font-size: 14px;
      gap: 6px;
    }

    .features {
      gap: 8px;
      grid-template-columns: 1fr 1fr;
      justify-content: flex-start;
    }

    .icons {
      gap: 8px;
    }
  }
}
