.hero {
  background-image: url("../../public/hero2.jpg");
  background-size: cover;
  height: 600px;
}
.container {
  width: 100%;
  margin: 0 auto 0 auto;
  height: 100%;
  padding-top: 100px;
  display: flex;

  align-items: center;
}
.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 80px;
}
.top .left h2 {
  font-family: "Source Sans 3", sans-serif;
  font-size: 55px;
  font-weight: 700;
  line-height: 66px;
  text-align: left;
  color: #ffffff;
}
.top .left p {
  font-family: "Source Sans 3", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #ffffff;
}
.button {
  display: flex;
  align-items: center;
}
.leftButton {
  background-color: #cc0001;
  color: #ffffff;
  padding: 8px 10px 8px 10px;
  border-top-left-radius: 0.25rem;
  width: 115px;
  text-align: center;
}
.rightButton {
  background-color: #efb0b0;
  padding: 8px 10px 8px 10px;
  border-top-right-radius: 0.25rem;
  width: 115px;
  text-align: center;
}
.box {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  background-color: white;
  border-bottom-right-radius: 0.25rem; /* 4px */
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  width: 441px;

  padding: 16px 20px 16px 20px;
}
.space-y > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.25rem /* 20px */ * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.25rem /* 20px */ * var(--tw-space-y-reverse));
}
.box form {
  margin: 20px 0 20px 0;
}
.box form select {
  color: gray;
}
.box form select option {
  color: #000;
}
.box .button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
.box button {
  padding: 16px 18px 16px 18px;
  width: 192px;
}
.box .button .leftButton {
  background-color: #efb0b0;
  color: #000;
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: center;
  border-radius: 0.5rem;
}
.box .button .rightButton {
  background-color: #cc0001;
  color: white;
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: center;
  border-radius: 0.5rem;
}
.box input {
  padding: 16px 18px 16px 18px;
  width: 100%;
  border-radius: 0.375rem;
  border: 1px solid gray;
}
.box select {
  border: 1px solid gray;
  padding: 16px 18px 16px 18px;
  width: 100%;
  border-radius: 0.375rem;
}
.right .bottomSide {
  background-color: #000;
  opacity: 0.8;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  color: white;
  padding: 2px 8px 2px 8px;
  border-radius: 10px;
  margin-top: 10px;
}
.flexgap1 {
  display: flex;
  align-items: center;
  gap: 4px;
}
/* media class */
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (max-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1152px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}
